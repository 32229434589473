const SET_OCR_VALUE = "REGISTER/SET_OCR_VALUE";
const SET_OCR_VALUE_2 = "REGISTER/SET_OCR_VALUE_2";

export default function reducer(
  state = {
    dataKtp: {
      nama_borrower: "",
      ktp: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      idjenis_kelamin: "",
      id_status_perkawinan: "",
      id_pendidikan_terakhir: "",
      id_agama:"",
      nwp: "",
      alamat_rumah: "",
      provinsi:"",
      kota: "",
      kecamatan: "",
      kelurahan: "",
      kode_pos: "",
      nama_pasangan:"",
      ktp_pasangan:"",
      ibu_kandung:"",
      status_rumah:"",
      lama_tinggal:"",
    },
  },
  action
) {
  switch (action.type) {
    case SET_OCR_VALUE:
      state.dataKtp.nama_borrower = action.payload?.dataKtp?.nama_borrower;
      state.dataKtp.ktp = action.payload?.dataKtp?.ktp;
      state.dataKtp.tempat_lahir = action.payload?.dataKtp?.tempat_lahir;
      state.dataKtp.tanggal_lahir = action.payload?.dataKtp?.tanggal_lahir;
      state.dataKtp.idjenis_kelamin = action.payload?.dataKtp?.idjenis_kelamin;
      state.dataKtp.id_status_perkawinan = action.payload?.dataKtp?.id_status_perkawinan;
      state.dataKtp.id_pendidikan_terakhir = action.payload?.dataKtp?.id_pendidikan_terakhir;
      state.dataKtp.id_agama = action.payload?.dataKtp?.id_agama;
      state.dataKtp.nwp = action.payload?.dataKtp?.nwp;

      state.dataKtp.nama_pasangan = action.payload?.dataKtp?.nama_pasangan;
      state.dataKtp.ktp_pasangan = action.payload?.dataKtp?.ktp_pasangan;
      state.dataKtp.ibu_kandung = action.payload?.dataKtp?.ibu_kandung;
      state.dataKtp.status_rumah = action.payload?.dataKtp?.status_rumah;
      state.dataKtp.lama_tinggal = action.payload?.dataKtp?.lama_tinggal;


      state.dataKtp.alamat_rumah = action.payload?.dataKtp?.alamat_rumah;
      state.dataKtp.provinsi = action.payload?.dataKtp?.provinsi;
      state.dataKtp.kota = action.payload?.dataKtp?.kota;
      state.dataKtp.kecamatan = action.payload?.dataKtp?.kecamatan;
      state.dataKtp.kelurahan = action.payload?.dataKtp?.kelurahan;
      state.dataKtp.kode_pos = action.payload?.dataKtp?.kode_pos;
      return state;
    default:
      return state;
  }
}

export function setOCRValue(ocrValue) {
  
  return { type: SET_OCR_VALUE, payload: { dataKtp: ocrValue } };
}

export function setOCRValue2(ocrValue) {
  
  return { type: SET_OCR_VALUE_2, payload: { dataKtp: ocrValue } };
}