import Cookies from "js-cookie";
import JwtDecode from "jwt-decode";
var CryptoJS = require("crypto-js");


export const isAuthenticated = () => {
  Cookies.getJSON("SID");
};

export const PostMessageRN = (data) => {
  if (window.hasOwnProperty("ReactNativeWebView")) {
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }
};

export const saveToken = (token) => {
  var tokenEncrypted = CryptoJS.AES.encrypt(token, process.env.REACT_APP_KEY_AES).toString();

  if (!token) {
    return;
  }
  Cookies.set("SID", tokenEncrypted);

  PostMessageRN({
    type: "cmd",
    value: "repro",
    event: "login",
    extraArgs: { userId: JwtDecode(token).id_user_client },
  });

  PostMessageRN({ type: "cmd", value: "set-cookie" });
};

export const saveTokenBrw = (token) => {
  var tokenEncrypted = CryptoJS.AES.encrypt(token, process.env.REACT_APP_KEY_AES).toString();
  if (!token) {
    return;
  }
  Cookies.set("SID", tokenEncrypted);

  PostMessageRN({
    type: "cmd",
    value: "repro",
    event: "login",
    extraArgs: { userId: JwtDecode(token).id_borrower },
  });

  PostMessageRN({ type: "cmd", value: "set-cookie" });
};

export const deleteAllToken = () => {
  Cookies.remove("SID");
};

export const getToken = (typeToken) => {
  const token = Cookies.get(typeToken);
  if (!token) {
    return false;
  }
  const bytes  = CryptoJS.AES.decrypt(token, process.env.REACT_APP_KEY_AES);
  const tokenjwt = bytes.toString(CryptoJS.enc.Utf8);

  return tokenjwt;
};

export const decodeAuth = () => {
  const ac = getToken("SID");
  if (ac) {
    return JwtDecode(ac);
  }
  return false;
};

export const doEraseAllReload = () => {
  PostMessageRN({ type: "cmd", value: "reload" });
};
