import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Modal, ModalBody, Button } from "reactstrap";
import HeaderPages from "./components/HeaderPages/HeaderPages";
import Layout from "./components/Layout/Layout";
import PrivateRoute from "./components/Layout/PrivateRoutes";
import { AppVersionWebview } from "./utils/constant";
import { PostMessageRN } from "./utils/cookie";
import { onMessageRN } from "./utils/rn-message";
import Loading from "./components/Loading/Loading";

const Login = lazy(() => import("./pages/Login/Login"));
const Register1 = lazy(() => import("./pages/Register/RegisterStep1"));
const RegisterKTP = lazy(() => import("./pages/Register_old/UploadKTP"));
const Register2 = lazy(() => import("./pages/Register_old/RegisterStep2"));
const Register3 = lazy(() => import("./pages/Register/RegisterStep3"));
const OTPRegister = lazy(() => import("./pages/Otp/Otp"));
const ResetPassword = lazy(() => import("./pages/ResetPassword/Reset"));
const EnterToken = lazy(() => import("./pages/ResetPassword/EnterToken"));
const Reset = lazy(() => import("./pages/ResetPassword/EnterPassword"));
const DeepLink = lazy(() => import("./pages/DeepLink/DeepLink"));
const LandingPage = lazy(() => import("./pages/LandingPage/LandingPage"));
const LandingReference = lazy(() =>
  import("./pages/LandingPage/LandingReference")
);
const GadaiMas = lazy(() => import("./pages/LandingPage/GadaiMas"));
const GadaiMasList = lazy(() => import("./pages/GadaiMasList/GadaiMasList"));
const LandingBorrower = lazy(() => import("./pages_borrower/LandingPage/LandingPage"));
const SimulasiPinjaman = lazy(() => import("./pages_borrower/SimulasiPinjaman/SimulasiPinjaman"));
const LandingBorrowerLogin = lazy(() => import("./pages_borrower/LandingPage/LandingPageLogin"));
const ComingSoonBorrower = lazy(() => import("./pages_borrower/LandingPage/ComingSoon"));
const Register1Brw = lazy(() => import("./pages_borrower/Register/GadaiMasList"));
const Register2Brw = lazy(() => import("./pages_borrower/Register/RegisterStep1"));
const LoginBrw = lazy(() => import("./pages_borrower/Login/Login"));
const ResetPasswordBrw = lazy(() => import("./pages_borrower/ResetPassword/Reset"));
const EnterPassword = lazy(() => import("./pages_borrower/ResetPassword/EnterPassword"));
const LokasiNotFound = lazy(() => import("./pages_borrower/Register/LokasiNotFound"));
const PenghentianSementara = lazy(() => import("./pages/PenghentianSementara/PenghentianSementara"));
const Perbaikan = lazy(() => import("./pages/Perbaikan/Perbaikan"));
const Kesalahan = lazy(() => import("./pages/Kesalahan/Kesalahan"));
const KebijakanPrivasi = lazy(() => import("./pages/KebijakanPrivasi/KebijakanPrivasi"));
const SyaratKetentuan = lazy(() => import("./pages/SyaratKetentuan/SyaratKetentuan"));
const OpenReport = lazy(() => import("./components/Report/DetailReport"));

onMessageRN();

export const App = (props) => {
  const [showModalForceUpdate, setShowModalForceUpdate] = useState(false);

  if (window.innerWidth > 1024) {
    window.location.href = "https://danain.co.id/";
    return <div></div>;
  }

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
          <HeaderPages>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route path="/login" component={Login} />
            <Route path="/login-borrower" component={LoginBrw} />
            <Route
              exact
              path="/landing-reference"
              component={LandingReference}
            />
            <Route
              path="/landing-reference/landing-page"
              component={LandingPage}
            />
            <Route
              exact
              path="/landing-reference/gadaimas"
              component={GadaiMas}
            />
            <Route
              exact
              path="/landing-reference/borrower"
              component={LandingBorrower}
            />
            <Route
              exact
              path="/landing-reference/borrower/simulasipinjaman"
              component={SimulasiPinjaman}
            />
            <Route
              exact
              path="/landing-reference/borrower/landing-page"
              component={LandingBorrowerLogin}
            />
             <Route
              exact
              path="/landing-reference/borrower/comingsoon"
              component={ComingSoonBorrower}
            />

            <Route
              path="/landing-reference/gadaimas/list"
              component={GadaiMasList}
            />
            <Route path="/danainapp" component={DeepLink} />
            <Route path="/register-step-1" component={Register1} />
            <Route path="/register-ktp" component={RegisterKTP} />
            <Route path="/register-step-2" component={Register2} />
            <Route path="/register-step-3" component={Register3} />
            <Route path="/register-borrower" component={Register1Brw} />
            <Route path="/register-borrower-create" component={Register2Brw} />
            <Route path="/otp-register" component={OTPRegister} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/reset-password-borrower" component={ResetPasswordBrw} />
            <Route path="/enter-token" component={EnterToken} />
            <Route path="/enter-password" component={Reset} />
            <Route path="/penghentian-sementara" component={PenghentianSementara} />
            <Route path="/enter-password-borrower" component={EnterPassword} />
            <Route path="/lokasi-not-found" component={LokasiNotFound} />
            <Route path="/openreport" component={OpenReport} />
            <Route path="/otp-register-1" component={OTPRegister} />
            <Route path="/perbaikan" component={Perbaikan} />
            <Route path="/kesalahan" component={Kesalahan} />
            <Route path="/informasi/kebijakan-privasi" component={KebijakanPrivasi} />
            <Route path="/informasi/syarat-ketentuan" component={SyaratKetentuan} />
            <Route path="/kebijakan-privasi" component={KebijakanPrivasi} />
            <PrivateRoute path="/dashboard">
              <Layout />
            </PrivateRoute>
          </HeaderPages>
        </Switch>
      </Suspense>
    </Router>
  );
};
