const SET_ERR_PRIVY = "GLOBAL/SET_ERR_PRIVY";

export default function reducer(
  state = {
    code: "",
    mustUpload: [],
    txtUpload: "",
    word: "",
    wordBottom: "",
  },
  action
) {
  switch (action.type) {
    case SET_ERR_PRIVY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function setErrPrivy(ErrPrivy) {
  
  return { type: SET_ERR_PRIVY, payload: { ErrPrivy } };
}

export function setERRPrivyAsync(ErrPrivy) {
  return (dispatch) => {
    if (ErrPrivy) {
      dispatch(setErrPrivy(ErrPrivy));
      return;
    }

      dispatch(setErrPrivy(ErrPrivy));
  };
}
