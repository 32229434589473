import React from "react";
import ReactDOM from "react-dom";
import dayjs from "dayjs";

import { Provider } from "react-redux";
import "./styles/css/index.css";
import "./styles/css/custom.css";
import { App } from "./App";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import configureStore from "./store/configureStore";
import { Slide, ToastContainer } from "react-toastify";
require("dotenv").config();
require("dayjs/locale/id");

const store = configureStore();
dayjs.locale("id");

// ReactGA.initialize({ trackingId: process.env.GOOGLE_ANALYTIC });
// ReactPixel.init(process.env.REACT_APP_FACEBOOK_ADS, null, { debug: true });
// TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_TAG });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer
        autoClose={2500}
        transition={Slide}
        position={"top-center"}
        closeOnClick
        draggable
        hideProgressBar={true}
        limit={1}
        closeButton={null}
      />
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
