import * as axios from "axios";
import qs from "querystring";
import { toast } from "react-toastify";
import { doEraseAllReload, getToken } from "./cookie";
import Formatting from "./formattingJson";
import Messages from "./message";

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Formatting.consoleWrite({
    //   value: error.response.data,
    //   name: "interceptors.error",
    // });

    if (error?.response?.data?.status === 500) {
        history.push("/kesalahan")
    }

    if (error.response === undefined) {
      toast.success("Ups, terjadi kesalahan!", {
        bodyClassName: "capitalize",
      });
    }

    if (error?.response?.data?.status === 401) {
      toast(Messages.unauthorized);
      setTimeout(() => {
        doEraseAllReload();
      }, 3000);
    }

    if (error?.response?.data?.status === 404) {
      Formatting.consoleWrite({
        value: error.response.data.message,
        name: "interceptors.response0001",
      });

      toast.success(error?.response?.data?.message, {
        bodyClassName: "capitalize",
      });
      // setTimeout(() => {
      //   doEraseAllReload();
      // }, 3000);
    }

    if (error?.response?.data?.status === 400) {
      Formatting.consoleWrite({
        value: error.response.data.message,
        name: "interceptors.response0001",
      });

      // toast.success(error?.response?.data?.message, {
      //   bodyClassName: "capitalize",
      // });

      if(!error?.response?.data?.message.includes("ratePencairan") && !error?.response?.data?.message.includes("nik sales tidak terdaftar") && !error?.response?.data?.message.includes("tidak ada parameter") && !error?.response?.data?.message.includes("referal tidak terdaftar") && !error?.response?.data?.message.includes("reset password") && !error?.response?.data?.message.includes("aktifkan") ){
        toast.success(error?.response?.data?.message, {
          bodyClassName: "capitalize",
        });
      }
      
      // setTimeout(() => {
      //   doEraseAllReload();
      // }, 3000);
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

const Api = () => ({
  Config: ({ withoutToken, multipartForm, typeService, appCheckVersion, ResetPassword = false }) => {
    let Api_token = getToken("SID");
    let basicAuth = Buffer.from(`${process.env.REACT_APP_UNAME_AUTH_V2}:${process.env.REACT_APP_PASSWD_AUTH_V2}`, 'utf8').toString('base64')

    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    if(ResetPassword){
      headers['golang-auth'] = process.env.REACT_APP_API_RESET_GOLANG_AUTH;
      headers["Authorization"] = `Basic ${basicAuth}`;
    }

    if (Api_token) {
      headers.token = `${Api_token}`;
    }

    if (withoutToken) {
      delete headers.token;
    }

    if (appCheckVersion) {
      headers["Key-auth"] = "FaSystem213";
    }

    if (multipartForm) {
      headers["Content-Type"] = "multipart/form-data";
    }

    const client = {
      baseURL: process.env.REACT_APP_API_ENDPOINT,
      headers: headers,
    };
    return client;
  },
  Get: (url = "", config) => {
    return axios
      .get(
        url,
        Api().Config({
          withoutToken: config?.withoutToken,
          appCheckVersion: config?.appCheckVersion,
        })
      )
      .then((res) => res?.data || {});
  },
  Post: (url, body, config) => {
    return axios
      .post(
        url,
        qs.stringify(body),
        Api().Config({ withoutToken: config?.withoutToken, ResetPassword: config?.ResetPassword })
      )
      .then((res) => res?.data || {});
  },
  PostFile: (url, body, config) => {
    return axios
      .post(url, body, Api().Config({ multipartForm: config?.multipartForm }))
      .then((res) => res?.data || {});
  },
});

export default Api;
