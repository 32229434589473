
import Formatting from "../../utils/formattingJson";

const SET_LIST_ID = "GLOBAL/SET_LIST_ID";
const SET_ACTIVE_FUND = "GLOBAL/SET_ACTIVE_FUND";
const SET_LIST_FILTER = "GLOBAL/SET_LIST_FILTER";
const SET_FILTER_MITRA = "GLOBAL/SET_FILTER_MITRA";

export default function reducer(state = { list_id: 0, activeFund : 2, listFilter : "", listFilterMitra : [] }, action) {
  
  switch (action.type) {
    // do reducer stuff
    case SET_LIST_ID:
      return { ...state, ...action.payload };
    case SET_ACTIVE_FUND:
      return { ...state, ...action.payload };
    case SET_LIST_FILTER:
      return { ...state, ...action.payload };
    case SET_FILTER_MITRA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function setListId(list_id) {
  
  return { type: SET_LIST_ID, payload: { list_id } };
}

export function setActiveFund(activeFund) {
  
  return { type: SET_ACTIVE_FUND, payload: {  activeFund  } };
}

export function setListFilter(listFilter) {
  
  return { type: SET_LIST_FILTER, payload: { listFilter } };
}

export function setListFilterMitra(listFilterMitra) {
  
  return { type: SET_FILTER_MITRA, payload: { listFilterMitra } };
}

export function setListIdAsync(list_id) {
  return (dispatch) => {
      dispatch(setListId(list_id));
  };
}

export function setActiveFundAsync(activeFund) {
  return (dispatch) => {
      dispatch(setActiveFund(activeFund));
  };
}

export function setListFilterAsync(listFilter) {
  return (dispatch) => {
      dispatch(setListFilter(listFilter));
  };
}

export function setListFilterMitraAsync(listFilterMitra) {
  return (dispatch) => {
      dispatch(setListFilterMitra(listFilterMitra));
  };
}
