const SET_OCR_VALUE = "REGISTER/SET_OCR_VALUE";
const SET_OCR_VALUE_2 = "REGISTER/SET_OCR_VALUE_2";
const SET_REGISTER_1 = "REGISTER/SET_REGISTER_1";
const SET_REGISTER_3 = "REGISTER/SET_REGISTER_3";
const SET_REGISTER_KTP = "REGISTER/SET_REGISTER_KTP";

export default function reducer(
  state = {
    dataKtp: {
      hp: "",
      email: "",
      nama: "",
      referal : "",
      idcompany: "",
      kode_unik: ""
    },
  },
  action
) {
  switch (action.type) {
    case SET_REGISTER_1:
      state.dataKtp.nama = action.payload?.nama;
      state.dataKtp.email = action.payload?.email;
      state.dataKtp.hp = action.payload?.hp;
      state.dataKtp.referal = action.payload.referal;
      state.dataKtp.kode_unik = action.payload.kode_unik;
      return state;
    case SET_REGISTER_3:
      state.dataKtp.idcompany = action.payload?.idcompany;
      return state;
    default:
      return state;
  }
}

export function setOCRValue(ocrValue) {
  
  return { type: SET_OCR_VALUE, payload: { dataKtp: ocrValue } };
}

export function setOCRValue2(ocrValue) {
  
  return { type: SET_OCR_VALUE_2, payload: { dataKtp: ocrValue } };
}

export function setRegister1(data) {
  
  return { type: SET_REGISTER_1, payload: { ...data } };
}

export function setRegisterKTP(data) {
  
  return { type: SET_REGISTER_KTP, payload: { ...data } };
}

export function setRegister3(data) {
  
  return { type: SET_REGISTER_3, payload: { ...data } };
}