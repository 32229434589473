import React, { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { getToken } from "../../utils/cookie";
import Formatting from "../../utils/formattingJson";
import TabMenu from "../ButtonTab/ButtonTab";

const Profile = lazy(() => import("../../pages/Profile/Profile"));
const FAQ = lazy(() => import("../../pages/FAQ/FAQ"));
const DetailFAQ = lazy(() => import("../../pages/FAQ/DetailFAQ"));
const DetailReportFAQ = lazy(() => import("../../pages/FAQ/DetailReport"));
const DetailReport = lazy(() =>
  import("../../pages/DetailReport/DetailReport")
);
const OTP = lazy(() => import("./../../pages/Otp/Otp"));
const Dashboard = lazy(() => import("../../pages/Dashboard/Dashboard"));
const Funding = lazy(() => import("../../pages/Funding/Funding"));
const ListFunding = lazy(() =>
  import("../../pages/Funding/TodayFunding/ListFunding")
);
const News = lazy(() => import("../../pages/News/News"));
const FundingSuccess = lazy(() =>
  import("../../pages/Funding/TodayFunding/FundingSuccess")
);
const DetailFunding = lazy(() =>
  import("../../pages/Funding/TodayFunding/DetailFunding")
);
const ChangePassword = lazy(() =>
  import("../../pages/ChangePassword/ChangePassword")
);
const ReadmeEventFunding = lazy(() =>
  import("../../pages/Funding/EventFunding/ReadmeEvent")
);
const FormEventFunding = lazy(() =>
  import("../../pages/Funding/EventFunding/FormFunding")
);
const ConfirmEventFunding = lazy(() =>
  import("../../pages/Funding/EventFunding/ConfirmFunding")
);
const WaitingEventFunding = lazy(() =>
  import("../../pages/Funding/EventFunding/WaitingFunding")
);
const ChangeBankAccount = lazy(() =>
  import("../../pages/ChangeBankAccount/ChangeBankAccount")
);
const DepositMoney = lazy(() =>
  import("../../pages/DepositMoney/DepositMoney")
);
const WithdrawMoney = lazy(() =>
  import("../../pages/WithdrawMoney/WithdrawMoney")
);
const GuideAutoLanding = lazy(() =>
  import("../../pages/AutoLanding/GuideAutoLanding")
);
const GuideReferal = lazy(() => import("../../pages/Referal/GuideReferal"));
const Voucher = lazy(() => import("../../pages/Voucher/Voucher"));
const AutoLanding = lazy(() => import("../../pages/AutoLanding/AutoLanding"));
const SaveBox = lazy(() => import("../../pages/SaveBox/SaveBox"));
const Referal = lazy(() => import("../../pages/Referal/Referal"));
const UpdateVirtualAccount = lazy(() =>
  import("../../pages/UpdateVirtualAccount/UpdateVirtualAccount")
);
const ConfirmVirtualAccount = lazy(() =>
  import("../../pages/UpdateVirtualAccount/ConfirmVirtualAccount")
);
const VerifikasiSelfie = lazy(() =>
  import("../../pages/Verifikasi/VerifikasiSelfie")
);
const VerifikasiSign = lazy(() =>
  import("../../pages/Verifikasi/VerifikasiSign")
);
const AvailableBalance = lazy(() =>
  import("../../pages/AvailableBalance/AvailableBalance")
);
const EventBalance = lazy(() =>
  import("../../pages/EventBalance/EventBalance")
);
const ActiveFunding = lazy(() =>
  import("../../pages/ActiveFunding/ActiveFunding")
);
const Yield = lazy(() => import("../../pages/Yield/Yield"));

const ReferalAgreement = lazy(() =>
  import("../../pages/Referal/ReferalAgreement")
);

const VerifikasiDigiSign = lazy(() =>
  import("../../pages/Verifikasi/verifikasiDigisign")
);

const VerifikasiDone = lazy(() =>
  import("../../pages/Verifikasi/VerifikasiDone")
);

const VerifikasiAktif = lazy(() =>
  import("../../pages/Verifikasi/VerifikasiAktif")
);

const SignDocument = lazy(() =>
  import("../../pages/SignDocument/SignDocument")
);

const UpdateHP = lazy(() => import("../../pages/UpdateHP/UpdateHP"));

const UpdateHPDone = lazy(() => import("../../pages/UpdateHP/UpdateHPDone"));

const Pin = lazy(() => import("../../pages/Pin/Pin"));

const NewPin = lazy(() => import("../../pages/Pin/CreatePIN"));

const NewPinConfirm = lazy(() => import("../../pages/Pin/CreatePINConfirm"));

const PinLanding = lazy(() => import("../../pages/Pin/PINLanding"));

const CheckPIN = lazy(() => import("../../pages/Pin/PinCheck"));

const ChangePIN = lazy(() => import("../../pages/ChangePIN/PinCheck"));

const CreateNewPIN = lazy(() => import("../../pages/ChangePIN/CreatePIN"));

const CreateNewPINConfirm = lazy(() =>
  import("../../pages/ChangePIN/CreatePINConfirm")
);

const EventBalanceNew = lazy(() =>
  import("../../pages/EventBalance/EventBalanceNew")
);

const ProgramFunding = lazy(() =>
  import("../../pages/EventBalance/ProgramFunding")
);

const DetailProgramFunding = lazy(() =>
  import("../../pages/EventBalance/DetailProgramFunding")
);

const TKB90 = lazy(() => import("../../pages/TKB90/TKB90"));

const Notification = lazy(() =>
  import("../../pages/Notification/Notification")
);

const DetailActiveFunding = lazy(() =>
  import("../../pages/ActiveFunding/DetailActiveFunding")
);

const DetailAvailableBalance = lazy(() =>
  import("../../pages/AvailableBalance/DetailAvailableBalance")
);

const VerifikasiLanding= lazy(() =>
  import("../../pages/Verifikasi/VerifikasiLanding")
);

const VerifikasiUpload= lazy(() =>
  import("../../pages/Verifikasi/VerifikasiUpload")
);

const VerifikasiReUpload= lazy(() =>
  import("../../pages/Verifikasi/VerifikasiReUpload")
);

const UploadKTP = lazy(() => 
  import("../../pages/Verifikasi/UploadKTP")
);

const EmailCheck = lazy(() => 
  import("../../pages/EmailCheck/EmailCheck")
);

const Biodata = lazy(() => 
  import("../../pages/Biodata/Biodata")
);

const BiodataStep2 = lazy(() => 
  import("../../pages/Biodata/BiodataStep2")
);

const BiodataDone = lazy(() => 
  import("../../pages/Biodata/BiodataDone")
);

const UploadSIM = lazy(() => 
  import("../../pages/Verifikasi/UploadSIM")
);

const UploadKK = lazy(() => 
  import("../../pages/Verifikasi/UploadKK")
);

const UploadKTPKK = lazy(() => 
  import("../../pages/Verifikasi/UploadKTPKK")
);

const UploadSelfieKTP = lazy(() => 
  import("../../pages/Verifikasi/VerifikasiSelfieKTP")
);



const ListArticle = lazy(() => import("../../pages/Article/Article"));

const Dashboard_Borrower = lazy(() => import("../../pages_borrower/Dashboard/Dashboard"));
const Pinjaman = lazy(() => import("../../pages_borrower/Pinjaman/Pinjaman"));
const EmailCheckBrw = lazy(() => import("../../pages_borrower/EmailCheck/EmailCheck"));
const CreatePasswordBorrower = lazy(() => import("../../pages_borrower/Register/RegisterStep3"));
const VerifikasiLandingBrw = lazy(() => import("../../pages_borrower/Verifikasi/VerifikasiLanding"));
const VerifikasiUploadKTPBrw = lazy(() => import("../../pages_borrower/Verifikasi/VerifikasiUploadKTP"));
const VerifikasiSelfieBrw = lazy(() => import("../../pages_borrower/Verifikasi/VerifikasiUploadSelfie"));
const UploadKTPBrw = lazy(() => import("../../pages_borrower/Verifikasi/UploadKTP"));
const UploadSelfieBrw = lazy(() => import("../../pages_borrower/Verifikasi/VerifikasiSelfie"));
const BiodataBrw = lazy(() => import("../../pages_borrower/Biodata/Biodata"));
const BiodataStep2Brw = lazy(() => import("../../pages_borrower/Biodata/BiodataStep2"));
const BiodataDoneBrw = lazy(() => import("../../pages_borrower/Biodata/BiodataDone"));
const VerifikasiReUploadBrw = lazy(() => import("../../pages_borrower/Verifikasi/VerifikasiReUpload"));

const UploadSIMBrw = lazy(() => 
  import("../../pages_borrower/Verifikasi/UploadSIM")
);

const UploadKKBrw = lazy(() => 
  import("../../pages_borrower/Verifikasi/UploadKK")
);

const UploadKTPKKBrw = lazy(() => 
  import("../../pages_borrower/Verifikasi/UploadKTPKK")
);

const UploadSelfieKTPBrw = lazy(() => 
  import("../../pages_borrower/Verifikasi/VerifikasiSelfieKTP")
);

const CreateBankBorrower = lazy(() => 
  import("../../pages_borrower/ChangeBankAccount/ChangeBankAccount")
);
const ProfileBorrower = lazy(() => import("../../pages_borrower/Profile/Profile"));

const UpdatePekerjaan = lazy(() => 
  import("../../pages_borrower/UpdatePekerjaan/UpdatePekerjaan")
);

const KenaliDanain = lazy(() => 
  import("../../pages_borrower/KenaliDanain/KenaliDanain")
);

const PengajuanPinjaman = lazy(() => 
  import("../../pages_borrower/PengajuanPinjaman/PengajuanPinjaman")
);

const QRCodeBrw = lazy(() => 
  import("../../pages_borrower/QRCode/QRCodeBrw")
);

const InformasiPribadi = lazy(() => 
  import("../../pages_borrower/InformasiPribadi/InformasiPribadi")
);

const DataPribadi = lazy(() => 
  import("../../pages_borrower/DataPribadi/DataPribadi")
);

const DataPekerjaan = lazy(() => 
  import("../../pages_borrower/DataPekerjaan/DataPekerjaan")
);

const ListBank = lazy(() => 
  import("../../pages_borrower/ChangeBankAccount/ListBank")
);

const PengaturanAkun = lazy(() => 
  import("../../pages_borrower/PengaturanAkun/PengaturanAkun")
);

const ChangeHandphone = lazy(() => 
  import("../../pages_borrower/ChangeHandphone/ChangeHandphone")
);

const ChangePasswordBrw = lazy(() => 
  import("../../pages_borrower/ChangePassword/ChangePassword")
);

const PilihJenisPinjaman = lazy(() => 
  import("../../pages_borrower/PilihJenisPinjaman/PilihJenisPinjaman")
);

const KonfirmasiJenisPinjaman = lazy(() => 
  import("../../pages_borrower/KonfirmasiPinjaman/KonfirmasiPinjaman")
);

const PilihBank = lazy(() => 
  import("../../pages_borrower/PilihBank/PilihBank")
);

const DetailPinjaman = lazy(() => 
  import("../../pages_borrower/Pinjaman/DetailPinjaman")
);

const TransaksiSelesai = lazy(() => 
  import("../../pages_borrower/KonfirmasiPinjaman/TransaksiSelesai")
);

const PengajuanPinjamanList = lazy(() => 
  import("../../pages_borrower/PengajuanPinjaman/GadaiMasList")
);

const Pembayaran = lazy(() => 
  import("../../pages_borrower/Pembayaran/Pembayaran")
);

const PembayaranDetail = lazy(() => 
  import("../../pages_borrower/Pembayaran/Pembayaran2")
);

const PembayaranDetailPanduan = lazy(() => 
  import("../../pages_borrower/Pembayaran/Pembayaran3")
);

const FAQBrw = lazy(() => import("../../pages_borrower/FAQ/FAQ"));
const DetailFAQBrw = lazy(() => import("../../pages_borrower/FAQ/DetailFAQ"));
const DetailReportFAQBrw = lazy(() => import("../../pages_borrower/FAQ/DetailReport"));
const DetailReportBrw = lazy(() =>
  import("../../pages/DetailReport/DetailReport")
);

const NotificationBrw = lazy(() =>
  import("../../pages_borrower/Notification/Notification")
);

const Pasti30 = lazy(() =>
  import("../../pages/Pasti30/Pasti30")
);

const DetailPasti30 = lazy(() =>
  import("../../pages/Pasti30/DetailPasti30")
);

const Collection = lazy(() =>
  import("../../pages/Collection/Collection")
);

const JaminanPengajuan = lazy(() =>
  import("../../pages_borrower/JaminanPengajuan/JaminanPengajuan")
);

const UploadJaminan = lazy(() =>
  import("../../pages_borrower/JaminanPengajuan/UploadJaminan")
);

const UploadBukti = lazy(() =>
  import("../../pages_borrower/JaminanPengajuan/UploadBukti")
);

const PengajuanGagal = lazy(() =>
  import("../../pages_borrower/JaminanPengajuan/PengajuanGagal")
);

const QRGenerate = lazy(() =>
  import("../../pages_borrower/JaminanPengajuan/QRGenerate")
);

const PPP = lazy(() =>
  import("../../pages/PerjanjianPenyaluranPendanaan/PPPLanding")
);

const EmailCheckUpdateHp = lazy(() => 
  import("../../pages_borrower/ChangeHandphone/EmailCheck")
);

const CheckOTP = lazy(() => 
  import("../../pages_borrower/ChangeEmail/OTPCheck")
);

const ChangeEmailBrw = lazy(() => 
  import("../../pages_borrower/ChangeEmail/ChangeEmail")
)

const ChangeEmailBrwSent = lazy(() => 
  import("../../pages_borrower/ChangeEmail/EmailCheck")
)

const UbahEmail = lazy(() => 
  import("../../pages_borrower/EmailCheck/UbahEmail")
)

Formatting.consoleWrite({
  value: localStorage.getItem("tes"),
  name: "SID",
});

export const Layout = () => (
  <Switch>
    <TabMenu>
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/dashboard/pin-landing" component={PinLanding} />
      <Route exact path="/dashboard/pin" component={Pin} />
      <Route exact path="/dashboard/checkpin" component={CheckPIN} />
      <Route exact path="/dashboard/new-pin" component={NewPin} />
      <Route exact path="/dashboard/new-pin-confirm" component={NewPinConfirm} />
      <Route exact path="/dashboard/email-check" component={EmailCheck} />
      <Route exact path="/dashboard/sign-ppp" component={PPP} />
      
      <Route
        exact
        path="/dashboard/new-pin-confirm"
        component={NewPinConfirm}
      />
      <Route exact path="/dashboard/faq" component={FAQ} />
      <Route path="/dashboard/faq/detail" component={DetailFAQ} />
      <Route path="/dashboard/faq/report" component={DetailReportFAQ} />
      <Route path="/dashboard/tkb90" component={TKB90} />
      <Route path="/dashboard/notification" component={Notification} />
      <Route path="/dashboard/detail-report" component={DetailReport} />

      <Route exact path="/dashboard/funding" component={Funding} />
      <Route exact path="/dashboard/funding">
              <Redirect to="/dashboard/funding/list-funding" />
      </Route>
      <Route exact path="/dashboard/funding/list-funding">
              <Redirect to="/penghentian-sementara" />
      </Route>

      <Route
        exact
        path="/dashboard/funding/list-funding"
        component={ListFunding}
      />
      <Route
        path="/dashboard/funding/list-funding/detail-funding"
        component={DetailFunding}
      />
      <Route
        path="/dashboard/funding/list-funding/funding-success"
        component={FundingSuccess}
      />
      <Route
        path="/dashboard/funding/event/readme"
        component={ReadmeEventFunding}
      />
      <Route
        path="/dashboard/funding/event/form-funding"
        component={FormEventFunding}
      />
      <Route
        path="/dashboard/funding/event/confirm-funding"
        component={ConfirmEventFunding}
      />
      <Route path="/dashboard/funding/event/otp" component={OTP} />
      <Route
        path="/dashboard/funding/event/waiting-funding"
        component={WaitingEventFunding}
      />

      <Route path="/dashboard/deposit" component={DepositMoney} />
      <Route path="/dashboard/withdraw" component={WithdrawMoney} />
      <Route path="/dashboard/voucher" component={Voucher} />
      <Route exact path="/dashboard/auto-landing" component={AutoLanding} />
      <Route
        path="/dashboard/auto-landing/guide"
        component={GuideAutoLanding}
      />
      <Route path="/dashboard/save-box" component={SaveBox} />
      <Route exact path="/dashboard/referal" component={Referal} />
      <Route path="/dashboard/referal/guide" component={GuideReferal} />
      <Route path="/dashboard/referal-agreement" component={ReferalAgreement} />
      <Route path="/dashboard/update-va" component={UpdateVirtualAccount} />
      <Route path="/dashboard/confirm-va" component={ConfirmVirtualAccount} />
      <Route
        exact
        path="/dashboard/available-balance"
        component={AvailableBalance}
      />
      <Route
        path="/dashboard/available-balance/detail-funding"
        component={DetailAvailableBalance}
      />
      <Route path="/dashboard/event-balance" component={EventBalance} />
      <Route
        exact
        path="/dashboard/event-balance-new"
        component={EventBalanceNew}
      />
      <Route
        exact
        path="/dashboard/event-balance-new/program-funding"
        component={ProgramFunding}
      />
      <Route
        path="/dashboard/event-balance-new/program-funding/detail-funding"
        component={DetailProgramFunding}
      />
      <Route exact path="/dashboard/active-funding" component={ActiveFunding} />
      <Route
        path="/dashboard/active-funding/detail-funding"
        component={DetailActiveFunding}
      />
      <Route path="/dashboard/yield" component={Yield} />
      <Route path="/dashboard/sign-doc" component={SignDocument} />
      <Route path="/dashboard/news" component={News} />
      <Route path="/dashboard/list-article" component={ListArticle} />
      <Route exact path="/dashboard/update-hp" component={UpdateHP} />
      <Route path="/dashboard/update-hp-done" component={UpdateHPDone} />
      <Route path="/dashboard/update-hp/otp" component={OTP} />
      <Route exact path="/dashboard/verifikasi/">
        <Redirect to="/dashboard/verifikasi/selfie" />
      </Route>
      <Route path="/dashboard/verifikasi/selfie" component={VerifikasiSelfie} />
      <Route path="/dashboard/verifikasi/landing" component={VerifikasiLanding} />
      <Route path="/dashboard/verifikasi/upload" component={VerifikasiUpload} />
      <Route path="/dashboard/verifikasi/re-upload" component={VerifikasiReUpload} />
      <Route path="/dashboard/verifikasi/sign" component={VerifikasiSign} />
      <Route path="/dashboard/verifikasi/uploadktp" component={UploadKTP} />
      <Route path="/dashboard/verifikasi/biodata" component={Biodata} />
      <Route path="/dashboard/verifikasi/biodata-step-2" component={BiodataStep2} />
      <Route path="/dashboard/verifikasi/biodata-done" component={BiodataDone} />
      <Route path="/dashboard/verifikasi/uploadsim" component={UploadSIM} />
      <Route path="/dashboard/verifikasi/uploadkk" component={UploadKK} />
      <Route path="/dashboard/verifikasi/uploadktpkk" component={UploadKTPKK} />
      <Route path="/dashboard/verifikasi/uploadselfiektp" component={UploadSelfieKTP} />
      <Route
        path="/dashboard/verifikasi/checkdigisign"
        component={VerifikasiDigiSign}
      />
      <Route path="/dashboard/verifikasi/done" component={VerifikasiDone} />
      <Route path="/dashboard/verifikasi/aktif" component={VerifikasiAktif} />

      <Route path="/dashboard/profile" exact component={Profile} />
      <Route
        path="/dashboard/profile/change-password"
        component={ChangePassword}
      />
      <Route path="/dashboard/profile/change-pin" component={ChangePIN} />
      <Route path="/dashboard/profile/create-pin" component={CreateNewPIN} />
      <Route
        path="/dashboard/profile/create-pin-confirm"
        component={CreateNewPINConfirm}
      />
      <Route
        path="/dashboard/profile/change-bank-account"
        component={ChangeBankAccount}
      />
      <Route exact path="/dashboard/pasti30" component={Pasti30} />
      <Route exact path="/dashboard/pasti30/detail-pasti30" component={DetailPasti30} />
      <Route exact path="/dashboard/collection" component={Collection} />

      <Route exact path="/dashboard/borrower">
        <Redirect to="/dashboard/borrower/dashboard" />
      </Route> 

      <Route exact path="/dashboard/borrower/dashboard" component={Dashboard_Borrower} />

      

      <Route exact path="/dashboard/borrower/pinjaman" component={Pinjaman} />
      <Route exact path="/dashboard/borrower/detail-pinjaman" component={DetailPinjaman} />
      <Route exact path="/dashboard/borrower/email-check-brw" component={EmailCheckBrw} />
      <Route exact path="/dashboard/borrower/new-email-check" component={UbahEmail} />
      <Route exact path="/dashboard/borrower/create-password" component={CreatePasswordBorrower} />
      <Route path="/dashboard/borrower/verifikasi/selfielanding" component={VerifikasiSelfieBrw} />
      <Route path="/dashboard/borrower/verifikasi/landing" component={VerifikasiLandingBrw} />
      <Route path="/dashboard/borrower/verifikasi/ktplanding" component={VerifikasiUploadKTPBrw} />
      <Route path="/dashboard/borrower/verifikasi/re-upload" component={VerifikasiReUploadBrw} />
      {/* <Route path="/dashboard/borrower/verifikasi/sign" component={VerifikasiSignBrw} /> */}
      <Route path="/dashboard/borrower/verifikasi/uploadselfie" component={UploadSelfieBrw} />
      <Route path="/dashboard/borrower/verifikasi/uploadktp" component={UploadKTPBrw} />
      <Route path="/dashboard/borrower/verifikasi/biodata" component={BiodataBrw} />
      <Route path="/dashboard/borrower/verifikasi/biodata-step-2" component={BiodataStep2Brw} />
      <Route path="/dashboard/borrower/verifikasi/biodata-done" component={BiodataDoneBrw} />
      <Route path="/dashboard/borrower/verifikasi/uploadsim" component={UploadSIMBrw} />
      <Route path="/dashboard/borrower/verifikasi/uploadkk" component={UploadKKBrw} />
      <Route path="/dashboard/borrower/verifikasi/uploadktpkk" component={UploadKTPKKBrw} />
      <Route path="/dashboard/borrower/verifikasi/uploadselfiektp" component={UploadSelfieKTPBrw} />
      <Route path="/dashboard/borrower/create-bank" component={CreateBankBorrower} />
      <Route path="/dashboard/borrower/update-pekerjaan" component={UpdatePekerjaan} />
      <Route path="/dashboard/borrower/profile" exact component={ProfileBorrower} />
      <Route path="/dashboard/borrower/kenali-danain" exact component={KenaliDanain} />
      <Route path="/dashboard/borrower/pengajuan-pinjaman" exact component={PengajuanPinjaman} />
      <Route path="/dashboard/borrower/pengajuan-pinjaman-list" exact component={PengajuanPinjamanList} />
      <Route path="/dashboard/borrower/qrcode" exact component={QRCodeBrw} />
      <Route path="/dashboard/borrower/informasi-pribadi" exact component={InformasiPribadi} />
      <Route path="/dashboard/borrower/data-pribadi" exact component={DataPribadi} />
      <Route path="/dashboard/borrower/data-pekerjaan" exact component={DataPekerjaan} />
      <Route path="/dashboard/borrower/list-bank" exact component={ListBank} />
      <Route path="/dashboard/borrower/setting-account" exact component={PengaturanAkun} />
      <Route path="/dashboard/borrower/change-hp" exact component={ChangeHandphone} />
      <Route path="/dashboard/borrower/change-password" exact component={ChangePasswordBrw} />
      <Route path="/dashboard/borrower/jenis-pinjaman" exact component={PilihJenisPinjaman} />
      <Route path="/dashboard/borrower/konfirmasi-jenis-pinjaman" exact component={KonfirmasiJenisPinjaman} />
      <Route path="/dashboard/borrower/pilih-bank" exact component={PilihBank} />
      <Route path="/dashboard/borrower/transaksi-done" exact component={TransaksiSelesai} />
      <Route path="/dashboard/borrower/pembayaran" exact component={Pembayaran} />
      <Route path="/dashboard/borrower/pembayaran-detail" exact component={PembayaranDetail} />
      <Route path="/dashboard/borrower/pembayaran-detail-panduan" exact component={PembayaranDetailPanduan} />
      <Route exact path="/dashboard/borrower/faq" component={FAQBrw} />
      <Route path="/dashboard/borrower/faq/detail" component={DetailFAQBrw} />
      <Route path="/dashboard/borrower/faq/report" component={DetailReportFAQBrw} />
      <Route path="/dashboard/borrower/notification" component={NotificationBrw} />
      <Route path="/dashboard/borrower/jaminan-pengajuan" component={JaminanPengajuan} />
      <Route path="/dashboard/borrower/upload-jaminan" component={UploadJaminan} />
      <Route path="/dashboard/borrower/upload-buktibeli" component={UploadBukti} />
      <Route path="/dashboard/borrower/pengajuan-gagal" component={PengajuanGagal} />
      <Route path="/dashboard/borrower/qr-generate" component={QRGenerate} />
      <Route path="/dashboard/borrower/check-change-hp" component={EmailCheckUpdateHp} />
      <Route path="/dashboard/borrower/check-change-email" component={CheckOTP} />
      <Route path="/dashboard/borrower/change-email" exact component={ChangeEmailBrw} />
      <Route path="/dashboard/borrower/change-email-sent" exact component={ChangeEmailBrwSent} />

    </TabMenu>
  </Switch>
);

export default Layout;
