const SET_DETAIL_EVENT_VALUE = "EVENT/SET_DETAIL_EVENT_VALUE";
const SET_CONFIRM_EVENT_VALUE = "EVENT/SET_CONFIRM_EVENT_VALUE";
const RESET_CONFIRM_EVENT_VALUE = "EVENT/RESET_CONFIRM_EVENT_VALUE";

const defaultState = {
  event_id: "",
  event_name: "",
  event_description: "",
  event_image: "",
  event_perjanjian: "",
  event_minimal: "",
  event_maksimal: "",
  nominal: 0,
  cashback: "",
  beginLockLending: "",
  endLockLending: "",
  event_kelipatan: "",
  event_periode: "",
  event_cashback_persen: "",
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_DETAIL_EVENT_VALUE:
      return { ...state, ...action.payload };
    case SET_CONFIRM_EVENT_VALUE:
      state.nominal = action.payload?.selectedAmount?.value;
      state.beginLockLending = action.payload?.beginLockLending;
      state.endLockLending = action.payload?.endLockLending;
      state.cashback = action.payload?.cashback;
      return state;
    case RESET_CONFIRM_EVENT_VALUE:
      return { ...state, ...defaultState };
    default:
      return state;
  }
}

export function setDetailEventValue(detailEventValue) {
  
  return { type: SET_DETAIL_EVENT_VALUE, payload: { ...detailEventValue } };
}

export function setFormPendanaan(detailFormConfirm) {
  
  return { type: SET_CONFIRM_EVENT_VALUE, payload: { ...detailFormConfirm } };
}

export function resetFormPendanaan() {
  
  return { type: RESET_CONFIRM_EVENT_VALUE };
}
