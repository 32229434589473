
const SET_TYPE_FUNDING = "GLOBAL/SET_TYPE_FUNDING";
const SET_FILTER_MITRA = "GLOBAL/SET_FILTER_MITRA";


export default function reducer(state = { typeFunding: 0, filterMitra : []}, action) {
  
  switch (action.type) {
    // do reducer stuff
    case SET_TYPE_FUNDING:
      return { ...state, ...action.payload };
    case SET_FILTER_MITRA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function setTypeFunding(typeFunding) {
  
  return { type: SET_TYPE_FUNDING, payload: { typeFunding } };
}

export function setFilterMitra(filterMitra) {
  
  return { type: SET_FILTER_MITRA, payload: { filterMitra } };
}


export function setTypeFundingAsync(typeFunding) {
  return (dispatch) => {
      dispatch(setTypeFunding(typeFunding));
  };
}

export function setFundingFilterMitraAsync(filterMitra) {
  return (dispatch) => {
      dispatch(setFilterMitra(filterMitra));
  };
}
