const SET_JAMINAN_VALUE = "REGISTER/SET_JAMINAN_VALUE";
const SET_JAMINAN_VALUE_2 = "REGISTER/SET_JAMINAN_VALUE_2";

export default function reducer(
  state = {
    dataJaminan: {
      jaminan: "",
      buktibeli: "",
    },
  },
  action
) {
  switch (action.type) {
    case SET_JAMINAN_VALUE:
      state.dataJaminan.jaminan = action.payload?.dataJaminan?.jaminan;
      return state;
    case SET_JAMINAN_VALUE_2:
      state.dataJaminan.buktibeli = action.payload?.dataJaminan?.buktibeli;
      return state
    default:
      return state;
  }
}

export function setJaminanValue(ocrValue) {
  
  return { type: SET_JAMINAN_VALUE, payload: { dataJaminan: ocrValue } };
}

export function setJaminanValue2(ocrValue) {
  
  return { type: SET_JAMINAN_VALUE_2, payload: { dataJaminan: ocrValue } };
}