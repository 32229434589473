export const routesHeaderFooterDisable = [
  "/splash-screen",
  "/onboard",
  "/login",
  "/dashboard/profile/change-password",
  "/dashboard/profile/change-pin",
  "/dashboard/profile/create-pin",
  "/dashboard/profile/create-pin-confirm",
  "/register-step-1",
  "/register-step-2",
  "/register-step-3",
  "/otp-register",
  "/otp-register-1",
  "/dashboard/tkb90",
  "/dashboard/news",
  "/dashboard/list-article",
  "/dashboard/notification",
  "/dashboard/profile/change-bank-account",
  "/dashboard/deposit",
  "/dashboard/email-check",
  "/dashboard/withdraw",
  "/dashboard/voucher",
  "/dashboard/auto-landing",
  "/dashboard/save-box",
  "/dashboard/referal",
  "/dashboard/referal-agreement",
  "/dashboard/update-va",
  "/dashboard/update-hp",
  "/dashboard/update-hp-done",
  "/dashboard/confirm-va",
  "/dashboard/available-balance",
  "/dashboard/available-balance/detail-funding",
  "/dashboard/event-balance",
  "/dashboard/event-balance-new",
  "/dashboard/event-balance-new/program-funding",
  "/dashboard/event-balance-new/program-funding/detail-funding",
  "/dashboard/active-funding",
  "/dashboard/active-funding/detail-funding",
  "/dashboard/yield",
  "/dashboard/sign-doc",
  "/dashboard/verifikasi/landing",
  "/dashboard/verifikasi/upload",
  "/dashboard/verifikasi/re-upload",
  "/dashboard/verifikasi/selfie",
  "/dashboard/verifikasi/sign",
  "/dashboard/verifikasi/checkdigisign",
  "/dashboard/verifikasi/done",
  "/dashboard/verifikasi/aktif",
  "/dashboard/verifikasi/uploadktp",
  "/dashboard/verifikasi/biodata",
  "/dashboard/verifikasi/biodata-step-2",
  "/dashboard/verifikasi/biodata-done",
  "/dashboard/verifikasi/uploadsim",
  "/dashboard/verifikasi/uploadkk",
  "/dashboard/verifikasi/uploadktpkk",
  "/dashboard/verifikasi/uploadselfiektp",
  "/dashboard/pin",
  "/dashboard/checkpin",
  "/dashboard/pin-landing",
  "/dashboard/new-pin",
  "/dashboard/new-pin-forgot",
  "/dashboard/new-pin-confirm",
  "/reset-password",
  "/enter-token",
  "/enter-password",
  "/login-borrower",
  "/landing-reference/borrower/simulasipinjaman",
  "/dashboard/borrower/email-check-brw",
  "/dashboard/borrower/create-password",
  "/dashboard/borrower/verifikasi/biodata-done",
  "/dashboard/borrower/create-bank",
  "/dashboard/borrower/update-pekerjaan",
  "/dashboard/borrower/kenali-danain",
  "/dashboard/borrower/pengajuan-pinjaman",
  "/dashboard/borrower/pengajuan-pinjaman-list",
  "/dashboard/borrower/informasi-pribadi",
  "/dashboard/borrower/data-pribadi",
  "/dashboard/borrower/data-pekerjaan",
  "/dashboard/borrower/list-bank",
  "/dashboard/borrower/setting-account",
  "/dashboard/borrower/change-hp",
  "/dashboard/borrower/change-email",
  "/dashboard/borrower/change-password",
  "/dashboard/borrower/jenis-pinjaman",
  "/dashboard/borrower/konfirmasi-jenis-pinjaman",
  "/dashboard/borrower/pilih-bank",
  "/dashboard/borrower/detail-pinjaman",
  "/dashboard/borrower/transaksi-done",
  "/dashboard/borrower/pembayaran",
  "/dashboard/borrower/pembayaran-detail",
  "/dashboard/borrower/pembayaran-detail-panduan",
  "/reset-password-borrower",
  "/enter-password-borrower",
  "/dashboard/borrower/notification",
  "/lokasi-not-found",
  "/dashboard/pasti30",
  "/dashboard/detail-pasti30",
  "/dashboard/collection",
  "/penghentian-sementara",
  "/dashboard/borrower/jaminan-pengajuan",
  "/dashboard/borrower/upload-jaminan",
  "/dashboard/borrower/upload-buktibeli",
  "/dashboard/borrower/pengajuan-gagal",
  "/dashboard/borrower/qr-generate",
  "/dashboard/sign-ppp",
  "/dashboard/borrower/check-change-hp",
  "/dashboard/borrower/check-change-email",
  "/dashboard/borrower/change-email-sent",

  // "/dashboard/borrower/pinjaman",
];
export const routesHeaderDisable = [
  "/splash-screen",
  "/onboard",
  "/dashboard",
  "/dashboard/borrower/dashboard",
  "/dashboard/pin-landing",
  "/dashboard/verifikasi/done",
  "/dashboard/verifikasi/aktif",
  "/dashboard/verifikasi/biodata-done",
  "/dashboard/borrower/verifikasi/biodata-done",
  "/dashboard/confirm-va",
  "/landing-reference",
  "/dashboard/borrower/profile",
  "/dashboard/borrower/transaksi-done",
  "/dashboard/borrower/pengajuan-gagal",
  
  // "/dashboard/borrower/qrcode",
  // "/landing-reference/borrower",
];

export const resetHistory = ["/dashboard/funding/event/waiting-funding"];

export const HeaderMenu = [
  {
    path: "/otp",
    label: "Verification code",
  },
  {
    path: "/login",
    label: "",
  },
  {
    path: "/dashboard/profile",
    label: "Profil",
  },
  {
    path: "/dashboard/faq",
    label: "Bantuan",
  },
  {
    path: "/dashboard/detail-report",
    label: "Portofolio",
  },
  {
    path: "/dashboard/funding",
    label: "Pendanaan",
  },
  {
    path: "/dashboard/funding/list-funding",
    label: "Pendanaan",
  },
  {
    path: "/dashboard/profile/change-password",
    label: "Ubah Kata Sandi",
  },
  {
    path: "/dashboard/profile/change-bank-account",
    label: "Ubah Rekening Bank",
  },
  {
    path: "/dashboard/deposit",
    label: "Setor Dana",
  },
  {
    path: "/dashboard/news",
    label: "Artikel",
  },
  {
    path: "/dashboard/list-article",
    label: "Artikel",
  },
  {
    path: "/dashboard/withdraw",
    label: "Tarik Dana",
  },
  {
    path: "/dashboard/voucher",
    label: "Hadiah",
  },
  {
    path: "/dashboard/auto-landing",
    label: "Pendanaan Otomatis",
  },
  {
    path: "/dashboard/save-box",
    label: "Brankas",
  },
  {
    path: "/dashboard/referal",
    label: "Ajak Teman",
  },
  {
    path: "/dashboard/referal-agreement",
    label: "Ajak Teman",
  },
  {
    path: "/dashboard/update-va",
    label: "Informasi Akun Bank",
  },
  {
    path: "/dashboard/update-hp",
    label: "Verifikasi Nomor Handphone",
  },
  {
    path: "/dashboard/update-hp-done",
    label: "Update Nomor Handphone",
  },
  {
    path: "/dashboard/confirm-va",
    label: "Konfirmasi",
  },
  {
    path: "/dashboard/sign-doc",
    label: "",
  },
  {
    path: "/dashboard/verifikasi/landing",
    label: "Data Diri",
  },
  {
    path: "/dashboard/verifikasi/uploadktp",
    label: "Unggah KTP",
  },
  {
    path: "/dashboard/verifikasi/upload",
    label: "Pengisian Data",
  },
  {
    path: "/dashboard/verifikasi/re-upload",
    label: "Pengisian Data",
  },
  {
    path: "/dashboard/verifikasi/selfie",
    label: "Unggah Swafoto",
  },
  {
    path: "/dashboard/verifikasi/sign",
    label: "Aktivasi Akun",
  },
  {
    path: "/dashboard/verifikasi/checkdigisign",
    label: "Aktivasi Akun",
  },
  {
    path: "/dashboard/verifikasi/done",
    label: "",
  },
  {
    path: "/dashboard/verifikasi/aktif",
    label: "",
  },
  {
    path: "/dashboard/verifikasi/biodata",
    label: "Data Pribadi",
  },
  {
    path: "/dashboard/verifikasi/biodata-step-2",
    label: "Data Alamat",
  },
  {
    path: "/dashboard/available-balance",
    label: "Saldo Tersedia",
  },
  {
    path: "/dashboard/available-balance/detail-funding",
    label: "Detail Pendanaan",
  },
  {
    path: "/dashboard/event-balance",
    label: "Saldo Program",
  },
  {
    path: "/dashboard/event-balance-new",
    label: "Saldo Program",
  },
  {
    path: "/dashboard/event-balance-new/program-funding",
    label: "Pendanaan Program",
  },
  {
    path: "/dashboard/event-balance-new/program-funding/detail-funding",
    label: "Detail Pendanaan",
  },
  {
    path: "/dashboard/active-funding",
    label: "Pendanaan Emas",
  },
  {
    path: "/dashboard/active-funding/detail-funding",
    label: "Detail Pendanaan",
  },

  {
    path: "/dashboard/yield",
    label: "Bunga",
  },
  {
    path: "/dashboard/funding/list-funding/detail-funding",
    label: "Detail Pendanaan",
  },
  {
    path: "/dashboard/funding/list-funding/funding-success",
    label: "Detail Pendanaan",
  },
  {
    path: "/dashboard/funding/event/readme",
    label: "Program Danain",
  },
  {
    path: "/dashboard/funding/event/form-funding",
    label: "Program Danain",
  },
  {
    path: "/dashboard/funding/event/confirm-funding",
    label: "Konfirmasi Program",
  },
  {
    path: "/dashboard/funding/event/waiting-funding",
    label: "Pembayaran",
  },
  {
    path: "/otp-register",
    label: "",
  },
  {
    path: "/otp-register-1",
    label: "",
  },
  {
    path: "/dashboard/funding/event/otp",
    label: "OTP",
  },
  {
    path: "/dashboard/pin",
    label: "PIN",
  },
  {
    path: "/dashboard/checkpin",
    label: "PIN",
  },
  {
    path: "/dashboard/new-pin",
    label: "PIN",
  },
  {
    path: "/dashboard/new-pin-forgot",
    label: "PIN",
  },
  {
    path: "/dashboard/new-pin-confirm",
    label: "Konfirmasi PIN",
  },
  {
    path: "/dashboard/profile/change-pin",
    label: "Ubah PIN",
  },
  {
    path: "/dashboard/profile/create-pin",
    label: "Ubah PIN",
  },
  {
    path: "/dashboard/profile/create-pin-confirm",
    label: "Ubah PIN",
  },
  {
    path: "/dashboard/tkb90",
    label: "TKB",
  },
  {
    path: "/dashboard/notification",
    label: "Notifikasi",
  },
  {
    path: "/dashboard/verifikasi/uploadsim",
    label: "Unggah SIM",
  },
  {
    path: "/dashboard/verifikasi/uploadkk",
    label: "Unggah KK",
  },
  {
    path: "/dashboard/verifikasi/uploadktpkk",
    label: "Unggah KTP atau KK",
  },
  {
    path: "/dashboard/verifikasi/uploadselfiektp",
    label: "Unggah Swafoto",
  },
  {
    path: "/dashboard/borrower/pinjaman",
    label: "Pinjaman",
  },
  {
    path: "/landing-reference/borrower/simulasipinjaman",
    label: "Simulasi Pinjaman",
  },
  
  { path: "/register-step-1", label: "" },
  { path: "/register-ktp", label: "Upload KTP" },
  { path: "/register-step-2", label: "Buat Akun Baru" },
  { path: "/register-step-3", label: "Buat Kata Sandi" },
  { path: "/reset-password", label: "Reset Kata Sandi" },
  { path: "/enter-token", label: "Enter Token" },
  { path: "/enter-password", label: "Reset Kata Sandi" },
  { path: "/dashboard/auto-landing/guide", label: "Panduan Pendanaan Oto..." },
  { path: "/dashboard/referal/guide", label: "Syarat dan Ketentuan" },
  { path: "/dashboard/update-hp/otp", label: "" },
  { path: "/dashboard/borrower/create-password", label: "Buat Kata Sandi" },
  {
    path: "/login-borrower",
    label: "",
  },
  {
    path: "/dashboard/borrower/verifikasi/landing",
    label: "Data Diri",
  },
  {
    path: "/dashboard/borrower/verifikasi/ktplanding",
    label: "Pengisian Data",
  },
  {
    path: "/dashboard/borrower/verifikasi/selfielanding",
    label: "Pengisian Data",
  },
  {
    path: "/dashboard/borrower/verifikasi/uploadktp",
    label: "Unggah KTP",
  },
  {
    path: "/dashboard/borrower/verifikasi/uploadselfie",
    label: "Unggah Swafoto",
  },
  {
    path: "/dashboard/borrower/verifikasi/biodata",
    label: "Data Pribadi",
  },
  {
    path: "/dashboard/borrower/verifikasi/biodata-step-2",
    label: "Data Alamat",
  },
  {
    path: "/dashboard/borrower/verifikasi/re-upload",
    label: "Pengisian Data",
  },
  {
    path: "/dashboard/borrower/verifikasi/uploadsim",
    label: "Unggah SIM",
  },
  {
    path: "/dashboard/borrower/verifikasi/uploadkk",
    label: "Unggah KK",
  },
  {
    path: "/dashboard/borrower/verifikasi/uploadktpkk",
    label: "Unggah KTP atau KK",
  },
  {
    path: "/dashboard/borrower/verifikasi/uploadselfiektp",
    label: "Unggah Swafoto",
  },
  {
    path: "/dashboard/borrower/create-bank",
    label: "Informasi Akun Bank",
  },
  {
    path: "/dashboard/borrower/profile",
    label: "Profil",
  },
  {
    path: "/dashboard/borrower/update-pekerjaan",
    label: "Informasi Pekerjaan",
  },
  {
    path: "/dashboard/borrower/pengajuan-pinjaman",
    label: "Pengajuan Pinjaman",
  },
  {
    path: "/dashboard/borrower/qrcode",
    label: "QR Code",
  },
  {
    path: "/dashboard/borrower/informasi-pribadi",
    label: "Informasi Pribadi",
  },
  {
    path: "/dashboard/borrower/data-pribadi",
    label: "Data Pribadi",
  },
  {
    path: "/dashboard/borrower/data-pekerjaan",
    label: "Informasi Pekerjaan",
  },
  {
    path: "/dashboard/borrower/list-bank",
    label: "Informasi Akun Bank",
  },
  {
    path: "/dashboard/borrower/setting-account",
    label: "Pengaturan Akun",
  },
  {
    path: "/dashboard/borrower/change-hp",
    label: "Ubah Nomor HP",
  },
  {
    path: "/dashboard/borrower/change-password",
    label: "Ubah Kata Sandi",
  },
  {
    path: "/dashboard/borrower/jenis-pinjaman",
    label: "Pengajuan Pinjaman",
  },
  {
    path: "/dashboard/borrower/konfirmasi-jenis-pinjaman",
    label: "Konfirmasi Pinjaman",
  },
  {
    path: "/dashboard/borrower/pilih-bank",
    label: "Informasi Akun Bank",
  },
  {
    path: "/dashboard/borrower/detail-pinjaman",
    label: "Detail Pinjaman",
  },
  {
    path: "/dashboard/borrower/pengajuan-pinjaman-list",
    label: "Lokasi Mitra",
  },
  {
    path: "/dashboard/borrower/pembayaran",
    label: "Bayar Pinjaman",
  },
  {
    path: "/dashboard/borrower/pembayaran-detail",
    label: "Bayar Pinjaman",
  },
  {
    path: "/dashboard/borrower/pembayaran-detail-panduan",
    label: "Panduan Pembayaran",
  },
  {
    path: "/reset-password-borrower",
    label: "Lupa Kata Sandi",
  },
  {
    path: "/enter-password-borrower",
    label: "Buat Kata Sandi Baru",
  },
  {
    path: "/dashboard/borrower/faq",
    label: "Bantuan",
  },
  {
    path: "/dashboard/borrower/notification",
    label: "Notifikasi",
  },
  {
    path: "/dashboard/pasti30",
    label: "Pendanaan",
  },
  {
    path: "/dashboard/pasti30/detail-pasti30",
    label: "Detail Pendanaan",
  },

  {
    path: "/dashboard/borrower/jaminan-pengajuan",
    label: "Pengajuan Pinjaman",
  },
  {
    path: "/dashboard/borrower/upload-jaminan",
    label: "Upload Pinjaman",
  },
  {
    path: "/dashboard/borrower/upload-buktibeli",
    label: "Upload Bukti Pembelian",
  },
  {
    path: "/dashboard/borrower/qr-generate",
    label: "Pengajuan Pinjaman",
  },
  {
    path: "/dashboard/collection",
    label: "Proses Penagihan",
  },
  {
    path: "/openreport",
    label: "Detail Dokumen",
  },
  {
    path: "/dashboard/borrower/change-email",
    label: "Ubah Alamat Email",
  },
  {
    path: "/dashboard/borrower/check-change-hp",
    label: "",
  },
  {
    path: "/dashboard/borrower/check-change-email",
    label: "",
  },
  {
    path: "/dashboard/borrower/change-email-sent",
    label: "",
  },
  { path: "/penghentian-sementara", label: "Danain" },
  {
    path: "/informasi/kebijakan-privasi",
    label: "Kebijakan Privasi",
  },
  {
    path: "/informasi/syarat-ketentuan",
    label: "Syarat & Ketentuan",
  },
  {
    path: "/kebijakan-privasi",
    label: "Kebijakan Privasi",
  },
];
