import { toast } from "react-toastify";
import Api from "../../utils/api";
import {
  deleteAllToken,
  doEraseAllReload,
  saveToken,
} from "../../utils/cookie";
import Messages from "../../utils/message";

const AuthApi = {
  postLogin: function (data) {
    return Api()
      .Post("msuser/datas/login", data)
      .then((data) => {
        toast.success(Messages.login.success);
        saveToken(data?.data);
        return data;
      });
  },
  doLogout: function () {
    return Api()
      .Get("msuser/users/logout")
      .then(() => {
        deleteAllToken();
        doEraseAllReload();
        return Promise.resolve();
      })
      .catch((err) => {
        console.error(err);
      });
  },
  appVersionCheck: function () {
    return Api().Get("sendgrid/versiAPK", {
      withoutToken: true,
      appCheckVersion: true,
    });
  },
};

export default AuthApi;
