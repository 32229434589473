import Formatting from "../../utils/formattingJson";

const SET_PIN = "GLOBAL/SET_PIN";

export default function reducer(state = { isNeedPIN: true }, action) {
  
  switch (action.type) {
    // do reducer stuff
    case SET_PIN:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function setPIN(isNeedPIN) {
  
  return { type: SET_PIN, payload: { isNeedPIN } };
}

export function setPINAsync(isNeedPIN) {
  return (dispatch) => {
    if (isNeedPIN) {
      dispatch(setPIN(isNeedPIN));
      return;
    }

      dispatch(setPIN(isNeedPIN));
  };
}

