import Api from "../../utils/api";
import { toast } from "react-toastify";
import Messages from "../../utils/message";

const UsersApi = {
  updatePassword: function (data) {
    return Api()
      .Post("msuser/users/updatepassword", data)
      .then(({ data }) => {
        toast.success(Messages.updatePassword.success);
      })
      .catch((err) => {
        toast.success(Messages.updatePassword.failed);
        console.error(err);
      });
  },
  updateBank: function (data) {
    return Api()
      .Post("msuser/users/updatebank", data)
      .then(({ data }) => {
        toast.success(Messages.updateBank.success);
      })
      .catch((err) => {
        toast.success(Messages.updateBank.failed);
        console.error(err);
      });
  },
  getBanks: function () {
    return Api()
      .Get("msuser/users/listallbank")
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  getDashboard: function () {
    return Api()
      .Get("msuser/users/beranda")
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  getFAQ: function () {
    return Api()
      .Get("msfaq/faq/listfaq", { withoutToken: true })
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  getDetailFAQ: function (id) {
    return Api()
      .Get(`msfaq/faq/detaillistfaq/${id}`, {
        withoutToken: true,
      })
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  getListMitra: function () {
    return Api()
      .Get("msuser/users/listmitra")
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  getOtp: function (hp) {
    return Api()
      .Get(`msuser/users/sendotp?hp=${hp}`)
      .then(() => {
        toast.success(Messages.getOtp.success);
      })
      .catch((err) => {
        toast.success(Messages.getOtp.failed);
      });
  },
  getResendOtp: function (hp) {
    return Api()
      .Get(`msuser/users/resendotp?hp=${hp}`)
      .then((res) => {
        toast.success(Messages.getOtp.success);
        return res;
      })
      .catch((err) => {
        return err;
        // toast.success(Messages.getOtp.failed);
      });
  },
  postOtp: function (otp) {
    return Api()
      .Post(`msuser/users/postotp`, otp)
      .then(() => {
        toast.success(Messages.confirmOtp.success);
        return;
      });
  },
  getHistory: function () {
    return Api()
      .Get(`msuser/users/history`)
      .then(({ data }) => data);
  },
  getDetailProfile: function () {
    return Api()
      .Get("msuser/users/detailprofile")
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  getPortfolio: function (paging = 1) {
    return Api()
      .Get(`msuser/users/laporankeuangan`)
      .then(({ data }) => data);
  },
  getDetailBankUser: function () {
    return Api()
      .Get("msuser/users/detailbankuser")
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  getImbalHasil: function (params = "") {
    return Api()
      .Get(`msuser/users/imbalhasil${params}`)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  PostResetPassword: function (data) {
    return Api()
      .Post(`msuser/datas/resetpassword`, data, {ResetPassword: true})
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  PostCheckTokenResetPassword: function (data) {
    return Api()
      .Post(`msuser/datas/checktokenresetpassword`, data)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  PostValidasiResetPassword: function (data) {
    return Api()
      .Post(`msuser/datas/validasiresetpassword`, data)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  PostLaporanDetail: function (detail) {
    return Api()
      .Post(`msuser/users/laporandetail`, detail)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  UpdateBankVa: function (detail) {
    return Api()
      .Post(`msuser/users/updateverifikasibank`, detail)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  getHPUser: function () {
    return Api()
      .Get("msuser/users/gethpuser")
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  postOTPRegist: function (hp) {
    return Api()
      .Post("msuser/users/sendotp", hp)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  postCreatePIN: function (pin) {
    return Api()
      .Post("msuser/users/createpin", pin)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  postUpdatePIN: function (pin) {
    return Api()
      .Post("msuser/users/updatepin", pin)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  postSendOTP: function () {
    return Api()
      .Post("msuser/users/sendotppin")
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  validasiOTPPin: function (data) {
    return Api()
      .Post("msuser/users/validasiotppin", data)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  getResendOtpPIN: function (hp) {
    return Api()
      .Get("msuser/users/resendotppin")
      .then((res) => {
        toast.success(Messages.getOtp.success);
        return res;
      })
      .catch((err) => {
        return err;
        // toast.success(Messages.getOtp.failed);
      });
  },
  postCreateForgotPIN: function (pin) {
    return Api()
      .Post("msuser/users/postresetpin", pin)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  getCheckPIN: function (pin) {
    return Api()
      .Get(`msuser/users/checkpin?pin=${pin}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
        // toast.success(Messages.getOtp.failed);
      });
  },
  getRiwayatSaldoEvent: function (params = "") {
    return Api()
      .Get(`msuser/users/riwayatsaldoevent${params}`)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  getEventPendanaan: function (params = "") {
    return Api()
      .Get(`msuser/users/eventpendanaan${params}`)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  getListEventFunding: function (params) {
    return Api()
      .Get(`msuser/users/eventpendanaan${params}`)
      .then(({ data }) => {
        return data;
      });
  },
  getListPortofolioEmas: function (params) {
    return Api()
      .Get(`msuser/users/portfolioemas${params}`)
      .then(({ data }) => {
        return data;
      });
  },
  getListPortofolioPasti30: function (params) {
    return Api()
      .Get(`msuser/users/portfolioemas${params}&idproduk=4`)
      .then(({ data }) => {
        return data;
      });
  },
  getAvailableBalance: function (params) {
    return Api()
      .Get(`msuser/users/riwayattsaldotersedia${params}`)
      .then(({ data }) => data);
  },
  getNotifikasi: function (params = "") {
    return Api()
      .Get(`msuser/users/listnotifikasi${params}`)
      .then(({ data }) => {
        return data;
      });
  },
  postReadNotif: function (data) {
    return Api()
      .Post("msuser/users/readnotifikasi", data)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  getStatusUpload: function () {
    return Api()
      .Get("msuser/users/statusupload")
      .then(({ data }) => {
        return data;
      });
  },
  getDataKTP: function () {
    return Api()
      .Get("msuser/users/dataktp")
      .then(({ data }) => {
        return data;
    });
  },
  getBanners: function (data) {
    return Api()
      .Get("msuser/marketing/listbenner", data)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  getAnnouncement: function (data) {
    return Api()
      .Get("msuser/marketing/listannounce", data)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  getNews: function (data) {
    return Api()
      .Get("msuser/marketing/listartikel", data)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  getOTPPrivy: function (params) {
    return Api()
      .Get(`mspendanaan/pendanaan/privy/requestotp${params}`)
      .then(({ data }) => {
        
        return data;
      });
  },
  postOTPPrivy: function (data) {
    return Api()
      .Post("mspendanaan/pendanaan/privy/pendanaan", data)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err);
      });
  },
  cekKoneksi: function () {
    return Api()
      .Get("msuser/datas/connect")
      .then(({ data }) => {
        return data;
      });
  },
  getMaintenance: function () {
    return Api()
      .Get("msuser/datas/statusMaintenance")
      .then(({ data }) => {
        return data;
    });
  },
  getKodeRequest : function (params) {
    return Api()
    .Get(`msuser/datas/koderequest/resetpassword?email=${params}`)
    .then(({data}) => data)
    .catch((err) => {
      console.error(err);
    })
  },
};

export default UsersApi;
