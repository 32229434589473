import React from "react";
import "./ButtonTab.css";
import { routesHeaderFooterDisable } from "../Constant/Constant";
import { useHistory } from "react-router-dom";
import IconBeranda from "../../styles/images/icon-beranda.svg";
import IconBantuan from "../../styles/images/icon-bantuan.svg";
import IconLaporanDetail from "../../styles/images/icon-laporan-detail.svg";
import IconPayung from "../../styles/images/icon-pendanaan.svg";
import IconQrCode from "../../styles/images/icon-qrcode.svg";
import IconQrCodeGreen from "../../styles/images/icon-qrcode-green.svg";
import IconProfile from "../../styles/images/icon-profile.svg";
import ActiveMenu from "../../styles/images/active-menu-dashboard.svg";
import MoneyBag from "../../styles/images/money_bag.svg";
import Livechat from "../../styles/images/Livechat.svg";

const TabMenu = ({ children }) => {
  let history = useHistory();

  if (
    routesHeaderFooterDisable.findIndex(
      (v) => v === history.location.pathname
    ) > -1
  ) {
    return children;
  }

  
  const isSubPages = () => {
    if (history.location.pathname) {
      const path = history.location.pathname;
      return path.split("/")[2] === "borrower" ? path.split("/").length > 4 : path.split("/").length > 3;
    }
  };

  const isBorrowerPages = () => {
    if (history.location.pathname) {
      const path = history.location.pathname;
      return path.split("/")[2] === "borrower";
    }
  };

  const handlePushRoutes = (routes = "") => {
    history.push(routes);
  };


  const isMenuBottomActive = (typeMenu) => {
    const path = history.location.pathname.split("/");

    const getLastPath = path[path.length - 1];
    const checkIsActive = getLastPath === typeMenu;

    return checkIsActive;
  }


  const isMenuActive = (typeMenu) => {
    const path = history.location.pathname.split("/");

    const getLastPath = path[path.length - 1];
    const checkIsActive = getLastPath === typeMenu;

    return (
      <img
        style={{
          bottom: 0,
          visibility: checkIsActive ? "visible" : "hidden",
        }}
        src={ActiveMenu}
      />
    );
  };

  if (isSubPages()) {
    return children;
  }

  if(!isBorrowerPages()){
    return (
      <>
        {children}
        <div className="appBottomMenu">
        <a onClick={() => handlePushRoutes("/")} className="item">
          <div className="col menu-icon">
            <img src={IconBeranda} className={isMenuBottomActive("dashboard") ? "filter-green" : ""} />
            <strong className={isMenuBottomActive("dashboard") ? "font-tab filter-green" : "font-tab"} >Beranda</strong>
            {isMenuActive("dashboard")}
          </div>
        </a>
        <a
          onClick={() => handlePushRoutes("/dashboard/detail-report")}
          className="item menu-icon"
        >
          <div className="col">
            <img src={IconLaporanDetail} className={isMenuBottomActive("detail-report") ? "filter-green" : ""} />
            <strong className={isMenuBottomActive("detail-report") ? "font-tab filter-green" : "font-tab"}>Portofolio</strong>
            {isMenuActive("detail-report")}
          </div>
        </a>
        <a
          onClick={() => handlePushRoutes("/dashboard/funding")}
          className="item menu-icon"
        >
          <div className="col">
            <img className="icon-payung" src={IconPayung} />
            <strong className="font-tab">Pendanaan</strong>
            {isMenuActive("funding")}
          </div>
        </a>
        <a onClick={() => handlePushRoutes("/dashboard/faq")} className="item">
          <div className="col menu-icon">
            <img src={IconBantuan} className={isMenuBottomActive("faq") ? "filter-green" : ""} />
            <strong className={isMenuBottomActive("faq") ? "font-tab filter-green" : "font-tab"}>Bantuan</strong>
            {isMenuActive("faq")}
          </div>
        </a>
        <a
          onClick={() => handlePushRoutes("/dashboard/profile")}
          className="item"
          data-toggle="modal"
          data-target="#sidebarPanel"
        >
          <div className="col menu-icon">
            <img src={IconProfile} className={isMenuBottomActive("profile") ? "filter-green" : ""} />
            <strong className={isMenuBottomActive("profile") ? "font-tab filter-green" : "font-tab"}>Profil</strong>
            {isMenuActive("profile")}
          </div>
        </a>
      </div>

      </>
    );
  }else{
    return (
      <>
        {children}
        <div className="appBottomMenu">
          <a onClick={() => handlePushRoutes("/dashboard/borrower/dashboard")} className="item">
            <div className="col menu-icon">
              <img src={IconBeranda} className={isMenuBottomActive("dashboard") ? "filter-green" : ""} />
              <strong className={isMenuBottomActive("dashboard") ? "font-tab filter-green" : "font-tab"}>Beranda</strong>
              {isMenuActive("dashboard")}
            </div>
          </a>
          <a
            onClick={() => handlePushRoutes("/dashboard/borrower/pinjaman")}
            className="item menu-icon"
          >
            <div className="col">
              <img src={MoneyBag} className={isMenuBottomActive("pinjaman") ? "filter-green" : ""}  />
              <strong className={isMenuBottomActive("pinjaman") ? "font-tab filter-green" : "font-tab"}>Pinjaman</strong>
              {isMenuActive("pinjaman")}
            </div>
          </a>
          <a
            onClick={() => handlePushRoutes("/dashboard/borrower/qrcode")}
            className="item menu-icon"
          >
            <div className="col">
              <img className="icon-payung" src={isMenuBottomActive("qrcode") ? IconQrCodeGreen : IconQrCode} />
              <strong className={isMenuBottomActive("qrcode") ? "font-tab filter-green" : "font-tab"}>QR Code</strong>
              {isMenuActive("funding")}
            </div>
          </a>
          <a onClick={() => handlePushRoutes("/dashboard/borrower/faq")} className="item">
            <div className="col menu-icon">
              <img src={Livechat} className={isMenuBottomActive("faq") ? "filter-green" : ""} />
              <strong className={isMenuBottomActive("faq") ? "font-tab filter-green" : "font-tab"}>Bantuan</strong>
              {isMenuActive("faq")}
            </div>
          </a>
          <a
            onClick={() => handlePushRoutes("/dashboard/borrower/profile")}
            className="item"
            data-toggle="modal"
            data-target="#sidebarPanel"
          >
            <div className="col menu-icon">
              <img src={IconProfile} className={isMenuBottomActive("profile") ? "filter-green" : ""} />
              <strong className={isMenuBottomActive("profile") ? "font-tab filter-green" : "font-tab"}>Profil</strong>
              {isMenuActive("profile")}
            </div>
          </a>
        </div>
      </>
    );
  }
};

export default TabMenu;
