import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import { routesHeaderDisable, HeaderMenu, resetHistory } from "../Constant/Constant";
import "./HeaderPages.css";
import BackArrow from "../../styles/images/back-arrow.svg";
import { GetFAQData } from "../../pages/FAQ/data/FAQ";
import AuthApi from "../../Services/auth/auth.api";
import { Offline, Online } from "react-detect-offline";
import Loading from "../../components/Loading/LoadingOffline";
import { polling } from "../../utils/constant";
import { toast } from "react-toastify";

const HeaderPages = ({ children }) => {
  const [doubleClick, setDoubleClick] = useState(0);
  const [isOnline, setIsOnline] = useState(0);

  let history = useHistory();

  // ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTIC);

  // ReactPixel.init(process.env.FACEBOOK_ADS,null,{debug:true});
  // TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_TAG });

  // ReactGA.pageview(history.location.pathname);
  // ReactPixel.pageView();

  useEffect(() => {
    // if(history.location.pathname === "/landing-reference"){
    //   if (document.getElementById("drift-frame-controller") !== null) {
    //     document.getElementById("drift-frame-controller").style.display = "block";
    //   }
    // }else{
    //   if (document.getElementById("drift-frame-controller") !== null) {
    //     document.getElementById("drift-frame-controller").style.display = "none";
    //   }
    // }
  }, []);

  const isOTPVerifyRegist = history.location.pathname === "/dashboard/update-hp/otp";

  const isEmailCheck = history.location.pathname === "/dashboard/email-check";
  const handleLabelMenu = () => {
    const {
      location: { state },
    } = history;

    if (state !== undefined && state.isFAQ) {
      const { title } = state;
      return title;
    }

    if (state !== undefined && state.isEvent) {
      const { title } = state;
      return title;
    }

    const item = HeaderMenu.find((v) => v.path === history.location.pathname);
    if (!item || item === undefined) {
      return "";
    }
    return item.label;
  };

  const isSubPages = () => {
    if (
      history.location.pathname === "/otp-register" ||
      history.location.pathname === "/otp-register-1" ||
      history.location.pathname === "/reset-password" ||
      history.location.pathname === "/reset-password-borrower" ||
      history.location.pathname === "/register-step-1" ||
      history.location.pathname === "/register-step-2" ||
      history.location.pathname === "/register-ktp" ||
      history.location.pathname === "/register-step-3" ||
      history.location.pathname === "/login" ||
      history.location.pathname === "/enter-token" ||
      history.location.pathname === "/enter-password" ||
      history.location.pathname === "/register-borrower" ||
      history.location.pathname === "/landing-reference/borrower" ||
      history.location.pathname === "/register-borrower-create" ||
      history.location.pathname === "/login-borrower" ||
      history.location.pathname === "/lokasi-not-found" ||
      history.location.pathname === "/openreport" ||
      history.location.pathname === "/penghentian-sementara"
    ) {
      return <img src={BackArrow} />;
    }

    if (history.location.pathname === "/dashboard/update-hp") {
      return "";
    }

    if (history.location.pathname === "/dashboard/borrower/pinjaman") {
      return "";
    }

    if (history.location.pathname === "/dashboard/checkpin") {
      return "";
    }

    if (history.location.pathname === "/landing-reference/borrower/comingsoon") {
      return "";
    }

    if (history.location.pathname === "/dashboard/borrower/qrcode") {
      return "";
    }

    if (history.location.pathname === "/dashboard/faq") {
      return "";
    }

    if (history.location.pathname === "/dashboard/profile") {
      return "";
    }

    if (history.location.pathname === "/dashboard/detail-report") {
      return "";
    }

    if (history.location.pathname === "/dashboard/borrower/faq") {
      return "";
    }

    if (history.location.pathname) {
      const path = history.location.pathname;
      return path.split("/").length > 2 ? <img src={BackArrow} /> : "";
    }
  };

  const handleBack = () => {
    if (history.location.pathname === "/dashboard" || history.location.pathname === "/dashboard/borrower/dashboard") {
      setDoubleClick(doubleClick + 1);
    }

    // if (history.location.pathname === "/dashboard/funding") {
    //   history.go(-(history.length - 1));
    // }

    if (history.location.pathname === "/register-step-1") {
      history.replace("/landing-reference/landing-page");
    }

    // if (history.location.pathname === "/dashboard/funding/event/waiting-funding") {
    //   history.go(-(history.length - 1));
    // }

    // if (resetHistory.findIndex((v) => v === history.location.pathname) > -1) {
    //   return (window.location.href =
    //     "https://affectionate-kirch-f523a2.netlify.app/dashboard/funding");
    // }

    history.goBack();
  };

  const handleLogout = () => {
    AuthApi.doLogout().then(() => {
      history.push("/landing-reference");
    });
  };

  const OnlineCHange = (e) => {
    if (e) {
      toast.success("Kembali Online");
    }
  };

  if (routesHeaderDisable.findIndex((v) => v === history.location.pathname) > -1) {
    return children;
  }

  // if (document.getElementById("drift-frame-controller") !== null) {
  //   document.getElementById("drift-frame-controller").style.display =
  //     "none";
  // }
  return (
    <>
      <Offline polling={polling} onChange={(e) => OnlineCHange(e)}>
        <Loading />
      </Offline>
      <div className="appHeader">
        <div className="left">
          {isSubPages() !== "" && (
            <a onClick={handleBack} className="headerButton goBack">
              {isSubPages()}
            </a>
          )}
        </div>
        <div className="pageTitle" style={{ marginTop: "-31px" }}>
          {handleLabelMenu()}
        </div>
        {isOTPVerifyRegist && (
          <div onClick={handleLogout} className="right hp-logout">
            Logout
          </div>
        )}
      </div>
      {children}
    </>
  );
};

export default HeaderPages;
