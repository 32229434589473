import UsersApi from "../Services/users/users.api";
/**
 * @constant AppVersionWebview
 * if version in webview not match with app
 * will show modal force update
 */
export const AppVersionWebview = "1.1.0";

export const PlayStoreLink =
  "https://play.google.com/store/apps/details?id=com.danainwbv.lender";

export const APIUrl = "https://go-prod.danain.co.id/api/webdanain/v1/user/hit";
export const polling = {enabled: true, interval: 50000, url: APIUrl};
export const OfflineText = "Gangguan Jaringan. Mohon periksa koneksi anda!.";
