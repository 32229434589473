
import Formatting from "../../utils/formattingJson";
import dayjs from "dayjs";

const SET_PRIODE = "GLOBAL/SET_PRIODE";
const SET_PARAMS_BALANCE = "GLOBAL/SET_PARAMS_BALANCE";
const SET_DYNAMIC_DATE_FILTER = "GLOBAL/SET_DYNAMIC_DATE_FILTER";
const SET_FILTER_MITRA = "GLOBAL/SET_FILTER_MITRA";


export default function reducer(state = { priode: 3, paramsBalance : "", dynamicDateFilter : dayjs(), filterMitra : []  }, action) {
  
  switch (action.type) {
    // do reducer stuff
    case SET_PRIODE:
      return { ...state, ...action.payload };
    case SET_PARAMS_BALANCE:
      return { ...state, ...action.payload };
    case SET_DYNAMIC_DATE_FILTER:
      return { ...state, ...action.payload };
    case SET_FILTER_MITRA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function setPriode(priode) {
  
  return { type: SET_PRIODE, payload: { priode } };
}

export function setParamsBalance(paramsBalance) {
  
  return { type: SET_PARAMS_BALANCE, payload: { paramsBalance } };
}

export function setDynamicDateFilter(dynamicDateFilter) {
  
  return { type: SET_DYNAMIC_DATE_FILTER, payload: { dynamicDateFilter } };
}

export function setFilterMitra(filterMitra) {
  
  return { type: SET_FILTER_MITRA, payload: { filterMitra } };
}

export function setPriodeAsync(priode) {
  return (dispatch) => {
      dispatch(setPriode(priode));
  };
}

export function setParamsBalanceAsync(paramsBalance) {
  return (dispatch) => {
      dispatch(setParamsBalance(paramsBalance));
  };
}

export function setDynamicDateFilterAsync(dynamicDateFilter) {
  return (dispatch) => {
      dispatch(setDynamicDateFilter(dynamicDateFilter));
  };
}

export function setFilterMitraAsync(filterMitra) {
  return (dispatch) => {
      dispatch(setFilterMitra(filterMitra));
  };
}