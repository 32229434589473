const SET_OCR_VALUE = "REGISTER/SET_OCR_VALUE";
const SET_OCR_VALUE_2 = "REGISTER/SET_OCR_VALUE_2";

export default function reducer(
  state = {
    dataKtp: {
      username: "",
      nik: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      jeniskelamin: "",
      pekerjaan: "",
      npwp: "",
      alamat: "",
      statusnikah:"",
      idagama: "",
      provinsi:"",
      kota: "",
      kecamatan: "",
      kelurahan: "",
      penghasilan:"",
      sumberpenghasilan:"",
      tujuan:"",
      kodepos: "",
      namaibukandung: "",
      idpendidikan: ""
    },
  },
  action
) {
  switch (action.type) {
    case SET_OCR_VALUE:
      state.dataKtp.username = action.payload?.dataKtp?.username;
      state.dataKtp.nik = action.payload?.dataKtp?.nik;
      state.dataKtp.tempat_lahir = action.payload?.dataKtp?.tempat_lahir;
      state.dataKtp.tanggal_lahir = action.payload?.dataKtp?.tanggal_lahir;
    //   state.dataKtp.bulan = action.payload?.dataKtp?.bulan;
    //   state.dataKtp.tahun = action.payload?.dataKtp?.tahun;
      state.dataKtp.jeniskelamin = action.payload?.dataKtp?.jeniskelamin;
      state.dataKtp.pekerjaan = action.payload?.dataKtp?.pekerjaan;
      state.dataKtp.statusnikah = action.payload?.dataKtp?.statusnikah;
      state.dataKtp.idagama = action.payload?.dataKtp?.idagama;
      state.dataKtp.idpendidikan = action.payload?.dataKtp?.idpendidikan;
      state.dataKtp.namaibukandung = action.payload?.dataKtp?.namaibukandung;


      state.dataKtp.npwp = action.payload?.dataKtp?.npwp;
      state.dataKtp.alamat = action.payload?.dataKtp?.alamat;
      state.dataKtp.provinsi = action.payload?.dataKtp?.provinsi;
      state.dataKtp.kota = action.payload?.dataKtp?.kota;
      state.dataKtp.kecamatan = action.payload?.dataKtp?.kecamatan;
      state.dataKtp.kelurahan = action.payload?.dataKtp?.kelurahan;
      state.dataKtp.penghasilan = action.payload?.dataKtp?.penghasilan;
      state.dataKtp.sumberpenghasilan = action.payload?.dataKtp?.sumberpenghasilan;
      state.dataKtp.tujuan = action.payload?.dataKtp?.tujuan;
      state.dataKtp.kodepos = action.payload?.dataKtp?.kodepos;
      return state;
    default:
      return state;
  }
}

export function setOCRValue(ocrValue) {
  
  return { type: SET_OCR_VALUE, payload: { dataKtp: ocrValue } };
}

export function setOCRValue2(ocrValue) {
  
  return { type: SET_OCR_VALUE_2, payload: { dataKtp: ocrValue } };
}