import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import loadingReducer from "../Services/globals/loading.reducer";
import setOCRValue  from "../Services/register/register.reducer";
import setOCRValue2  from "../Services_borrower/register/register.reducer";
import detailEventValue  from "../Services/Event/event.reducer";
import pinReducer from "../Services/globals/pin.reducer";
import listIdReducer from "../Services/users/program.reducer";
import historyReducer from "../Services/users/history.reducer";
import fundingReducer from "../Services/users/funding.reducer";
import dataOCR  from "../Services/users/ocr.reducer";
import dataOCR2  from "../Services_borrower/users/ocr.reducer";
import ErrPrivy  from "../Services/globals/ErrorPrivy.reducer";
import dataJaminan  from "../Services_borrower/users/jaminan.reducer";

const makeRootReducer = (history) => {
  return combineReducers({
    router: connectRouter(history),
    global: loadingReducer,
    ocrValue: setOCRValue,
    ocrValue2: setOCRValue2,
    detailEventValue: detailEventValue,
    pin: pinReducer,
    program : listIdReducer,
    history : historyReducer,
    funding : fundingReducer,
    dataOCR : dataOCR,
    dataOCR2 : dataOCR2,
    dataErrPrivy : ErrPrivy,
    dataJaminan : dataJaminan,
  });
};

export default makeRootReducer;
